/** @format */
import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import {
  getBookingWithStatusChartApi,
  getCatGDataApi,
  getDashboardDataApi,
  getRatingAndBookingCountApi,
} from "../../service/dashboard.service";
import Picker from "react-month-picker";
import { getCategoryApi } from "../../service/serviceProvider.service";
import socket from "../../utils/socket";

const Dashboard = () => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilterWeekMonth, setSelectedFilterWeekMonth] = useState(null);
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const [gData, setGData] = useState([]);
  const [catGData, setCatGData] = useState([]);
  const [search, setSearch] = useState("");
  const [clearData, setClearData] = useState(false);
  const [spDetail, setSpDetail] = useState({
    spCount: 0,
    customerCount: 0,
  });
  const [bookingRating, setBoookingRating] = useState({
    bookingCount: [],
    ratingCount: [],
    labels: [],
  });
  const filter = [
    {
      name: "Today",
      code: "today",
      startDate: new Date(),
      endDate: new Date(),
    },
    {
      name: "Yesterday",
      code: "yesterday",
      startDate: moment().subtract(1, "days").toDate(),
      endDate: moment().subtract(1, "days").toDate(),
    },
    {
      name: "This week",
      code: "ThisWeek",
      startDate: moment().startOf("week").toDate(),
      endDate: new Date(),
    },
    {
      name: "This month",
      code: "ThisMonth",
      startDate: moment().startOf("month").toDate(),
      endDate: new Date(),
    },
    {
      name: "This year",
      code: "ThisYear",
      startDate: moment().startOf("year").toDate(),
      endDate: new Date(),
    },
    {
      name: "Custom",
      code: "custom",
      startDate: moment().subtract(1, "year").toDate(),
      endDate: new Date(),
    },
  ];
  const handleFilter = (iValue) => {
    setSelectedFilter(iValue);
    if (iValue === "custom") {
      setIsCustomDate(true);
      return;
    }
    setIsCustomDate(false);
    let value = filter.find((item) => item.code === iValue);
    setDate([value.startDate, value.endDate]);
    setFilterDate({
      startDate: moment(value.startDate).format("YYYY-MM-DD"),
      endDate: moment(value.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleFilterWeekMonth = (value) => {
    setSelectedFilterWeekMonth(value);
  };

  const filterData = (sDate) => {
    setDate(sDate);
    setSearch("");

    if (sDate.length === 2 && sDate[0] && sDate[1]) {
      // Update the state with the filtered data
      setFilterDate({
        startDate: moment(sDate[0]).format("YYYY-MM-DD"),
        endDate: moment(sDate[1]).format("YYYY-MM-DD"),
      });
      setSelectedFilter(null);
    }
  };
  useEffect(() => {
    let startDateF = moment().startOf("year").format("YYYY-MM-DD");
    let endDateF = moment().format("YYYY-MM-DD");
    setDate([new Date(startDateF), new Date(endDateF)]);
    setFilterDate({ startDate: startDateF, endDate: endDateF });
    setSelectedFilter("ThisYear");
  }, [clearData]);

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [chartBooking, setChartBooking] = useState({});
  const [chartBookingData, setChartBookingData] = useState({});
  const [bookingStatusData, setBookingStatusData] = useState([]);
  const [isCustomDate, setIsCustomDate] = useState(false);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: bookingRating?.labels,
      datasets: [
        {
          label: "Rating",
          type: "bar",
          data: bookingRating?.ratingCount,
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
        },
        {
          type: "bar",
          label: "Booking",
          data: bookingRating?.bookingCount,

          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [bookingRating]);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data2 = {
      labels: catGData?.labels,

      datasets: catGData?.datasets?.map((item) => {
        return {
          label: item.label,
          data: item.data,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4,
        };
      }),
    };
    const options2 = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData2(data2);
    setChartOptions2(options2);
  }, [catGData]);

  //chart with booking status category
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    const data = {
      labels: bookingStatusData?.labels,
      datasets: [
        {
          label: "Placed",
          data: bookingStatusData?.placedBooking,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4,
        },

        {
          label: "Hired",
          data: bookingStatusData?.HiredBooking,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--orange-500"),
          tension: 0.4,
        },
        {
          label: "Completed",
          data: bookingStatusData?.completeBooking,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--green-500"),
          tension: 0.4,
        },
        {
          label: "Report",
          data: bookingStatusData?.reportBooking,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--red-500"),
          tension: 0.4,
        },
        {
          label: "Expired",
          data: bookingStatusData?.expiredBooking,
          fill: false,
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };
    setChartBookingData(data);
    setChartBooking(options);
  }, [bookingStatusData]);

  const getDashboardData = async () => {
    try {
      let startDateF = filterDate?.startDate;
      let endDateF = filterDate?.endDate;
      if (!filterDate?.startDate || !filterDate?.endDate) return;

      let res = await getDashboardDataApi({
        startDate: `${startDateF} 00:00:00`,
        endDate: `${endDateF} 23:59:59`,
      });

      setSpDetail(res?.data?.data?.userDetail);
    } catch (error) {
      console.log(error);
    }
  };
  const getBookingAndRatingData = async () => {
    try {
      let startDateF = filterDate?.startDate;
      let endDateF = filterDate?.endDate;
      if (!startDateF || !endDateF) return;
      let res = await getRatingAndBookingCountApi({
        startDate: `${startDateF} 00:00:00`,
        endDate: `${endDateF} 23:59:59`,
      });

      setBoookingRating({
        bookingCount: res?.data?.data?.bookingCount,
        ratingCount: res?.data?.data?.ratingCount,
        labels: res?.data?.data?.labels,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashboardData();
    getBookingAndRatingData();
  }, [filterDate]);
  useEffect(() => {
    getBookingWithStatusChart();
  }, [filterDate, selectedFilterWeekMonth]);
  const getCat = async () => {
    try {
      let res = await getCategoryApi();
      if (res?.status === 200) {
        let catData = res?.data?.data?.map((cat) => {
          return {
            name: cat?.name,
            id: cat?.id,
          };
        });

        setCategoryData(catData);
        setSelectedFilterWeekMonth(catData[0]);
      }
    } catch (error) {}
  };
  const getBookingWithStatusChart = async () => {
    try {
      let startDateF = filterDate?.startDate;
      let endDateF = filterDate?.endDate;
      if (!selectedFilterWeekMonth?.id || !startDateF || !endDateF) return;

      let res = await getBookingWithStatusChartApi({
        startDate: `${startDateF} 00:00:00`,
        endDate: `${endDateF} 23:59:59`,
        cat_id: selectedFilterWeekMonth?.id,
      });
      if (res?.status === 200) {
        setBookingStatusData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkToday = () => {
    const inputDate = moment(
      `${filterDate?.endDate} 23:59:59`,
      "YYYY-MM-DD HH:mm:ss"
    );

    const currentDate = moment();

    return inputDate.isSameOrAfter(currentDate, "day");
  };
  useEffect(() => {
    getCat();
  }, []);
  useEffect(() => {
    //for socket
    let isDayIncl = checkToday();
    socket.on("register", (data) => {
      if (isDayIncl) {
        if (data?.type === "customer") {
          setSpDetail((prev) => ({
            ...prev,
            customerCount: prev?.customerCount + 1,
          }));
        } else {
          setSpDetail((prev) => ({ ...prev, spCount: prev?.spCount + 1 }));
        }
      }
    });
    socket.on("ratingBooking", (data) => {});
  }, [filterDate]);
  return (
    <>
      <section className="admin-content-wrapper">
        <div className="adminContent" style={{ minHeight: "520px" }}>
          <div className="tablefilterheader pb-2">
            <div className="tableHeader">
              <h4 className="h4 mb-0">Dashboard</h4>
              <div className="tableFilterRow ml-auto">
                <div className="tableFilterCol" style={{ width: 170 }}>
                  <Dropdown
                    value={selectedFilter}
                    onChange={(e) => handleFilter(e.value)}
                    options={filter}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="filter"
                    className="filterDropdown"
                  />
                </div>
                {isCustomDate && (
                  <div
                    className="tableFilterCol dateFilter"
                    style={{
                      minWidth: "235px",
                      width: "auto",
                    }}
                  >
                    <div className="combDateBox w-full">
                      <span className="c-label">Date</span>
                      <Calendar
                        value={date}
                        onChange={(e) => filterData(e.value)}
                        showIcon
                        maxDate={new Date()}
                        className="w-auto"
                        placeholder="Start Date - End Date"
                        selectionMode="range"
                        // view="month"
                      />
                    </div>
                  </div>
                )}

                <div className="tableFilterCol" style={{ width: "auto" }}>
                  <Button
                    className="btn btn-orange"
                    onClick={() => {
                      setClearData(!clearData);
                      setIsCustomDate(false);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="divider mt-2 mb-4"></div>
          <div className="container-fluid px-4">
            <div className="grid">
              <div className="lg:col-4 md:col-4 sm:col-6">
                <div className="dash-box">
                  <i className="pi pi-user" style={{ fontSize: "2.5rem" }}></i>
                  <div className="relative text-center">
                    <h6>New Registered SP</h6>
                    <h5 className="mb-0">{spDetail?.spCount}</h5>
                  </div>
                </div>
              </div>
              <div className="lg:col-4 md:col-4 sm:col-6">
                <div className="dash-box">
                  <i className="pi pi-user" style={{ fontSize: "2.5rem" }}></i>
                  <div className="relative text-center ">
                    <h6>New Registered Customers</h6>
                    <h5 className="mb-0">{spDetail?.customerCount}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mt-4">
              <div className="lg:col-12 md:col-12 sm:col-12">
                <h6 className="h6">Rate count vs Total completed orders </h6>
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            </div>

            <div className="grid mt-4">
              <div className="lg:col-12 md:col-12 sm:col-12">
                <div className="flex align-items-center justify-content-between">
                  <h6 className="h6">Category </h6>
                  <Dropdown
                    value={selectedFilterWeekMonth}
                    onChange={(e) => handleFilterWeekMonth(e.value)}
                    options={categoryData}
                    optionLabel="name"
                    placeholder="filter"
                    className="filterDropdown"
                  />
                </div>
                <Chart
                  type="bar"
                  data={chartBookingData}
                  options={chartBooking}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
